// src/pages/ItemDetail.js

import React,{useEffect} from "react";
import { useParams } from "react-router-dom";
import ruberbands from "../data/rubberBands";
import Snowfall from "react-snowfall";  
import { Helmet } from "react-helmet";
const RuberDetail = () => {
useEffect(() => {
  try{
     (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  catch(e)
  {
    console.log(e);
  }

})
  
  const { id } = useParams();
  const item = ruberbands.find((item) => item.id === parseInt(id));

  if (!item) {
    return (
      <div className="mt-10 text-center text-red-500">Item not found!</div>
    );
  }

  return (
    <div className="max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
      <Snowfall />
      <img
        src={item.image}
        alt={item.name}
        className="object-cover w-full h-64 rounded-t-lg"
      />
      <Helmet>
        <title>Akila Suppliers - Rubber Bands Details</title>
        <meta
          name="description"
          content={`View details for ${item.name}, including sizes, stock availability, and more. Explore our range of rubber bands at Akila Suppliers.`}
        />
        <meta
          name="keywords"
          content={`rubber bands, ${item.name}, rubber bands details, Akila Suppliers, office supplies, elastic bands`}
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content={`Akila Suppliers - Rubber Bands Details: ${item.name}`}
        />
        <meta
          property="og:description"
          content={`Discover detailed information about ${item.name}. Check out the available sizes, stock status, and other relevant details at Akila Suppliers.`}
        />
        <meta
          property="og:url"
          content={`https://akilasuppliers.com/rubber-bands/${item.id}`}
        />
        <meta property="og:image" content={item.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`Akila Suppliers - Rubber Bands Details: ${item.name}`}
        />
        <meta
          name="twitter:description"
          content={`Get detailed information about ${item.name} including sizes and stock availability. Available at Akila Suppliers.`}
        />
        <meta name="twitter:image" content={item.image} />

        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <div className="p-4">
        <h2 className="mb-2 text-2xl font-bold">{item.name}</h2>
        <p className="mb-4 text-gray-700">{item.description}</p>
        <div className="mb-2 text-gray-600">
          <span className="font-semibold">Sizes:</span> {item.sizes.join(", ")}
        </div>
        <div className="text-gray-600">
          <span className="font-semibold">In Stock:</span>{" "}
          {item.inStock ? "Yes" : "No"}
        </div>
        <div className="mt-6 ml-6">
          <a
            href="tel:+94717009059"
            className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
          >
            Call to Order
          </a>
          <a
            href="tel:+94717009059"
            className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
          >
            Inquiry
          </a>
        </div>
      </div>
    </div>
  );
};

export default RuberDetail;
