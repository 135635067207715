import HouseholdImg from "../images/gloves/HouseHold.jpeg";
import HouseholdImg2 from "../images/gloves/HouseHoldGloves-02.jpeg";
import dotGloves from "../images/gloves/dotGloves.jpeg";
import Surgicle from "../images/gloves/glvoes.jpeg";

const gloves = [
  {
    id: 1,
    image: HouseholdImg,
    name: "Premium Rubber Gloves Household",
    description:
      "Experience unmatched durability and comfort with these high-quality rubber gloves, perfect for all your household tasks. Available now at an incredibly affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 2,
    image: HouseholdImg2,
    name: "Premium Rubber Gloves ",
    description:
      "Keep your hands safe and dry with these premium rubber gloves, designed for style and protection in any situation. Grab yours today at an unbeatable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 3,
    image: dotGloves,
    name: "Dot Gloves Jacket",
    description:
      "Stay comfortable and protected during work with these dot gloves, combining grip and flexibility for all tasks. Get them now at a price you won't believe!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 4,
    image: Surgicle,
    name: "Surgical Glove ",
    description:
      "Ensure your safety with these surgical gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
];

export default gloves;
