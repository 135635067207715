import React,{useEffect} from "react";
import { Helmet } from "react-helmet";
import Snowfall from "react-snowfall";  
const Item = ({ item }) => {


  useEffect(() => {

    try{
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch(e)
    {
      console.log(e);
    }})
  return (
    <div className="relative max-w-sm overflow-hidden bg-white border border-gray-200 rounded-lg shadow-md">
      <div className="relative">
        <Snowfall />
        <Helmet>
          <title>Akila Suppliers Items - Quality Items</title>
          <meta
            name="description"
            content="Find a wide range of safety items at Akila Suppliers. We offer gloves, rubber bands, aprons, raincoats, surgical gloves, cotton gloves, boots, and more at the best affordable prices."
          />
          <meta
            name="keywords"
            content="safety items, gloves, rubber bands, aprons, raincoats, boots, surgical gloves, cotton gloves, Akila Suppliers"
          />
          <meta name="robots" content="index,follow" />
          <meta
            property="og:title"
            content="Akila Suppliers - Your One-Stop Shop for Safety Items"
          />
          <meta
            property="og:description"
            content="Shop for all your safety needs at Akila Suppliers. Explore our wide range of high-quality gloves, rubber bands, aprons, raincoats, and more."
          />
          <meta
            property="og:image"
            content="https://example.com/home-og-image.jpg"
          />
          <meta property="og:url" content="https://akilasuppliers.com" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Akila Suppliers - Your One-Stop Shop for Safety Items"
          />
          <meta
            name="twitter:description"
            content="Discover top-quality safety items at Akila Suppliers. We provide a variety of products to keep you safe."
          />
          <meta
            name="twitter:image"
            content="https://example.com/home-twitter-image.jpg"
          />
          <meta property="og:title" content="Akila Suppliers" />
          <meta
            property="og:description"
            content="Explore safety items such as gloves, masks, and aprons."
          />
          <meta
            property="og:image"
            content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
          />
          <meta property="og:url" content="https://akilasuppliers.com/" />

          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
            crossorigin="anonymous"
          ></script>
        </Helmet>

        <img
          src={item.image}
          alt={item.name}
          className="object-cover w-full h-48"
        />
        <div className="absolute top-0 right-0 flex items-center px-2 py-1 bg-yellow-200 rounded-bl-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 3h6a2 2 0 012 2v3.586a1 1 0 00.293.707l4.414 4.414a2 2 0 010 2.828l-4.414 4.414a2 2 0 01-2.828 0l-4.414-4.414a1 1 0 00-.707-.293H5a2 2 0 01-2-2V9a2 2 0 012-2h3.586a1 1 0 00.707-.293L13.707 2.293A2 2 0 0115.414 2H18a2 2 0 012 2v3.586l-2-2V4h-3.586a1 1 0 00-.707.293l-4.414 4.414a2 2 0 000 2.828L15.414 18a1 1 0 010 1.414l-4.414 4.414a2 2 0 01-2.828 0l-4.414-4.414a2 2 0 010-2.828l4.414-4.414a1 1 0 00.293-.707V5a2 2 0 012-2z"
            />
          </svg>
          <span className="text-xs font-semibold text-yellow-800">20% OFF</span>
        </div>
      </div>

      <div className="p-4">
        <h2 className="text-xl font-semibold text-gray-800">{item.name}</h2>
        <p className="mt-2 text-gray-600">{item.description}</p>

        <div className="mt-4">
          <h3 className="text-sm font-medium text-gray-700">
            Available Sizes:
          </h3>
          <div className="flex mt-2 space-x-2">
            {item.sizes.map((size, index) => (
              <span
                key={index}
                className="inline-block px-2 py-1 text-xs text-gray-800 bg-gray-200 rounded"
              >
                {size}
              </span>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <span
            className={`inline-block px-3 py-1 rounded text-xs font-medium ${
              item.inStock
                ? "bg-green-200 text-green-800"
                : "bg-red-200 text-red-800"
            }`}
          >
            {item.inStock ? "In Stock" : "Out of Stock"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Item;
