// src/components/AvailableItems.js

import React from "react";
import OurItems from "../data/OurItems";

const AvailableItems = () => {

 
  return (
    <div className="py-6 bg-gray-400">
      <div className="container px-4 mx-auto">
        <h1 className="mb-4 text-xl font-bold text-center text-black">
          Our Available Items-Call For Your Order-0717009059
        </h1>
        <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {OurItems.map((item) => (
            <div
              key={item.id}
              className="flex items-center p-2 mb-2 transition-transform transform bg-gray-600 border-l-4 border-yellow-500 rounded-md shadow-sm hover:shadow-md hover:scale-100"
            >
              <span className="text-xs font-medium text-white">
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailableItems;
