// src/pages/Gloves.js

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Item from "../pages/Item";
import gloves from "../data/glovesData";
import GlovesDetail from "./GlovesDetail";
import "../App.css";
import { Helmet } from "react-helmet";
import SearchBar from "../component/SearchBar"; // Ensure this path is correct
import Snowfall from "react-snowfall";  

const Gloves = () => {
  // Sort gloves data to show available stock first
  const sortedGloves = [...gloves].sort((a, b) => b.inStock - a.inStock);

  // State for search query and scroll to top button visibility
  const [searchQuery, setSearchQuery] = useState("");
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  // Handle search query
  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  // Filter sorted gloves based on search query
  const filteredGloves = sortedGloves.filter((item) =>
    item.name.toLowerCase().includes(searchQuery)
  );

  // Scroll to top button visibility handler
  useEffect(() => {

    try{
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch(e)
    {
      console.log(e)
    }
    const handleScroll = () => {
      setShowScrollTopButton(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen py-10 bg-gray-100">
      <Snowfall />
      <Helmet>
        <title>Gloves Collection - Akila Suppliers</title>
        <meta
          name="description"
          content="Explore Akila Suppliers' wide range of durable and versatile gloves, suitable for various needs. From cotton gloves to loova gloves, we have the right protection for you."
        />
        <meta
          name="keywords"
          content="gloves, safety gloves, cotton gloves, loova gloves, durable gloves, Akila Suppliers"
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content="Gloves Collection - Akila Suppliers"
        />
        <meta
          property="og:description"
          content="Discover the perfect gloves for your needs at Akila Suppliers. We offer a variety of high-quality gloves, ensuring safety and durability."
        />
        <meta
          property="og:image"
          content="https://example.com/gloves-og-image.jpg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/gloves" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Gloves Collection - Akila Suppliers"
        />
        <meta
          name="twitter:description"
          content="Shop for high-quality, versatile gloves at Akila Suppliers."
        />
        <meta
          name="twitter:image"
          content="https://example.com/gloves-twitter-image.jpg"
        />

        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        {/* Page Header */}
        <header className="mb-8 text-center">
          <h1 className="text-3xl font-extrabold text-gray-800">
            Gloves Collection-Low Prize with Quality Products-0717009059
          </h1>
          <p className="mt-2 text-gray-600">
            Explore our wide range of durable and versatile gloves.
          </p>
        </header>
        <div className="flex justify-center mb-8">
          <SearchBar onSearch={handleSearch} />
        </div>

        {/* Item Grid */}
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {filteredGloves.length > 0 ? (
            filteredGloves.map((item) => (
              <Link key={item.id} to={`/item/${item.id}`} className="group">
                <Item item={item} />
              </Link>
            ))
          ) : (
            <div className="text-center text-gray-600 col-span-full">
              No items available.
            </div>
          )}
        </div>

        {/* Routes */}
        <Routes>
          <Route path="/item/:id" element={<GlovesDetail />} />
        </Routes>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTopButton && (
        <button
          onClick={scrollToTop}
          className="fixed p-3 text-white transition duration-300 bg-blue-500 rounded-full shadow-lg bottom-4 right-4 hover:bg-blue-600"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default Gloves;
