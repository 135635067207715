import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.png";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    // Close the mobile menu when the route changes
    closeMobileMenu();
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobileMenuOpen) {
        closeMobileMenu();
      }
    };

    // Close the mobile menu on scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileMenuOpen]);

  return (
    <header className="fixed top-0 left-0 z-50 w-full text-white bg-gray-800 ">
      <div className="container flex items-center justify-between px-6 py-4 mx-auto">
        {/* Logo */}
        <Link to="/">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="w-8 h-8 mr-3" />
            <span className="text-xl font-bold">Akila Suppliers</span>
          </div>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden space-x-6 md:flex">
          <Link to="/" className="hover:text-gray-400">
            Home
          </Link>
          <Link to="/ruber-page" className="hover:text-gray-400">
            Ruber Bands Images
          </Link>
          <Link to="/gloves-page" className="hover:text-gray-400">
            Gloves Images
          </Link>
          <Link to="/services" className="hover:text-gray-400">
            How to Order
          </Link>
          <Link to="/about" className="hover:text-gray-400">
            About
          </Link>

          <Link to="/contact" className="hover:text-gray-400">
            Contact
          </Link>
          <Link to="/privacy" className="hover:text-gray-400">
            Privacy & Policy
          </Link>
        </nav>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white-700 hover:text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      <nav
        className={`md:hidden bg-gray-800 ${
          isMobileMenuOpen ? "block" : "hidden"
        }`}
      >
        <Link
          to="/"
          className="block px-6 py-4 text-gray-400 hover:text-white"
          onClick={closeMobileMenu}
        >
          Home
        </Link>
        <Link
          to="/ruber-page"
          className="block px-6 py-4 text-gray-400 hover:text-white"
          onClick={closeMobileMenu}
        >
          Ruber Bands Images
        </Link>
        <Link
          to="/gloves-page"
          className="block px-6 py-4 text-gray-400 hover:text-white"
          onClick={closeMobileMenu}
        >
          Gloves Images
        </Link>
        <Link
          to="/services"
          className="block px-6 py-4 text-gray-400 hover:text-white"
          onClick={closeMobileMenu}
        >
          How to Order Items
        </Link>
        <Link
          to="/about"
          className="block px-6 py-4 text-gray-400 hover:text-white"
          onClick={closeMobileMenu}
        >
          About
        </Link>

        <Link
          to="/contact"
          className="block px-6 py-4 text-gray-400 hover:text-white"
          onClick={closeMobileMenu}
        >
          Contact
        </Link>
        <Link
          to="/privacy"
          className="block px-6 py-4 text-gray-400 hover:text-white"
          onClick={closeMobileMenu}
        >
          Privacy & Policy
        </Link>
      </nav>
    </header>
  );
};

export default Header;
