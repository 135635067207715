// src/components/SearchBar.js

import React, { useState } from "react";
import { Helmet } from "react-helmet";

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleSearch = () => {
    onSearch(query);
  };

  return (
    <div className="flex items-center max-w-lg mx-auto overflow-hidden bg-white rounded-full shadow-lg">
    
      <Helmet>
        <title>
          Akila Suppliers Items Search Bar,Gloves ,RuberBand,apron,raincort,boots all
          safety items available
        </title>

        <meta
          name="description"
          content="safety items gloves,rubber bands,apron,raincort,surgicle gloves,cotton gloves,boots ,serviate and all of the safety items.for best affordable prize"
        />
      </Helmet>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search for items..."
        className="flex-1 p-3 text-gray-700 placeholder-gray-400 transition duration-300 bg-gray-100 border-none rounded-l-full outline-none focus:ring-2 focus:ring-blue-500"
      />
      <button
        onClick={handleSearch}
        className="px-4 py-2 text-white transition duration-300 bg-blue-600 rounded-r-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <svg
          className="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 0l6 6"
          />
        </svg>
      </button>
    </div>
  );
};

export default SearchBar;
