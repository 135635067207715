import "../App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Item from "../pages/Item";
import items from "../data/ItemData";
import ItemDetail from "./ItemDetail";
import SearchBar from "../component/SearchBar";
import AvailableItem from "../component/AvailableItem";
import { Helmet } from "react-helmet";
import Snowfall from "react-snowfall";  
const Home = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchQuery)
  );

  const availableItems = filteredItems.filter((item) => item.inStock);
  const unavailableItems = filteredItems.filter((item) => !item.inStock);

  const sortedItems = [...availableItems, ...unavailableItems];

  // Show the scroll-to-top button when the user scrolls down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {

    try{
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch(e)
    {
      console.log(e);
    }
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <Snowfall />
      <Helmet>
        <title>Akila Suppliers - Your One-Stop Shop for Safety Items</title>
        <meta
          name="description"
          content="Find a wide range of safety items at Akila Suppliers. We offer gloves, rubber bands, aprons, raincoats, surgical gloves, cotton gloves, boots, and more at the best affordable prices."
        />
        <meta
          name="keywords"
          content="safety items, gloves, rubber bands, aprons, raincoats, boots, surgical gloves, cotton gloves, Akila Suppliers"
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content="Akila Suppliers - Your One-Stop Shop for Safety Items"
        />
        <meta
          property="og:description"
          content="Shop for all your safety needs at Akila Suppliers. Explore our wide range of high-quality gloves, rubber bands, aprons, raincoats, and more."
        />
        <meta
          property="og:image"
          content="https://example.com/home-og-image.jpg"
        />
        <meta property="og:url" content="https://akilasuppliers.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Akila Suppliers - Your One-Stop Shop for Safety Items"
        />
        <meta
          name="twitter:description"
          content="Discover top-quality safety items at Akila Suppliers. We provide a variety of products to keep you safe."
        />
        <meta
          name="twitter:image"
          content="https://example.com/home-twitter-image.jpg"
        />
        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <div className="max-w-6xl p-12 mx-auto ">
        {/* Search Bar */}
        <div className="flex justify-center mb-5">
          <SearchBar onSearch={handleSearch} />
        </div>

        {/* Available Items */}
        <AvailableItem />

        {/* Item Grid */}
        <div className="grid grid-cols-1 gap-8 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {sortedItems.length > 0 ? (
            sortedItems.map((item) => (
              <Link key={item.id} to={`/item/${item.id}`} className="group">
                <Item item={item} />
              </Link>
            ))
          ) : (
            <div className="text-center text-gray-600 col-span-full">
              No items found.
            </div>
          )}
        </div>

        {/* Scroll To Top Button */}
        {isVisible && (
          <button
            onClick={scrollToTop}
            className="fixed p-3 text-white transition-opacity duration-300 bg-blue-600 rounded-full shadow-lg bottom-4 right-4 focus:outline-none focus:ring focus:ring-blue-300"
          >
            ↑
          </button>
        )}

        {/* Routes */}
        <Routes>
          <Route path="/item/:id" element={<ItemDetail />} />
        </Routes>
      </div>
    </div>
  );
};

export default Home;
