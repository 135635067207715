import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Snowfall from "react-snowfall";  
const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleNavigation = () => {
    // Ensure the modal is closed after navigating to a new page
    setIsModalOpen(false);
  };

  return (
    <footer className="py-16 text-gray-200 bg-gray-800">
      <div className="container px-6 mx-auto">
        {/* Contact Details */}

        <div className="mb-12 text-center">
          <h4 className="mb-4 text-3xl font-bold text-white">Contact Us</h4>
          <Snowfall />
          <div className="mb-6">
            <a
              href="tel:+94717009059"
              className="block px-6 py-3 mb-2 text-lg font-medium text-white transition duration-300 bg-blue-600 rounded-md shadow-md hover:bg-blue-700"
            >
              Call Us: 0717009059
            </a>
            <a
              href="tel:+94707824328"
              className="block px-6 py-3 text-lg font-medium text-white transition duration-300 bg-blue-600 rounded-md shadow-md hover:bg-blue-700"
            >
              Call Us: 0707824328
            </a>
          </div>
          <p className="text-lg text-gray-300">
            Email:{" "}
            <a
              href="mailto:akilasuppliers@gmail.com"
              className="text-blue-400 hover:underline"
            >
              akilasuppliers@gmail.com
            </a>
          </p>
        </div>

        {/* Sinhala Navigation */}
        <div className="mb-12 text-center">
          <h4 className="mb-4 text-2xl font-semibold text-white">
            ප්‍රධාන අංග
          </h4>
          <ul className="space-y-2">
            <li className="text-gray-300 hover:text-white">
              <Link to="/gloves-page">සියලු ග්ලෝව්ස් වර්ග ලබා ගත හැකිය</Link>
            </li>
            <li className="text-gray-300 hover:text-white">
              <Link to="/ruber-page">සියලු රබර් බෑන්ඩ් වර්ග ලබා ගත හැකිය</Link>
            </li>
            <li className="text-gray-300 hover:text-white">
              <Link to="/">සියලු ආරක්ෂිත උපකරණ ලබා ගත හැකිය</Link>
            </li>
          </ul>
        </div>

        {/* Footer Sections */}
        <div className="grid grid-cols-1 gap-8 mb-12 md:grid-cols-3">
          {/* Company Info */}
          <div>
            <h3 className="mb-4 text-xl font-semibold text-white">
              Akila Suppliers
            </h3>
            <p className="text-gray-300">
              Your trusted source for high-quality safety items. We are
              committed to providing top-notch products and exceptional service.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="mb-4 text-xl font-semibold text-white">
              Quick Links
            </h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/"
                  className="text-gray-300 transition duration-300 hover:text-yellow-400"
                >
                  {" "}
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/ruber-page"
                  className="text-gray-300 transition duration-300 hover:text-yellow-400"
                >
                  {" "}
                  Rubber Bands Images
                </Link>
              </li>
              <li>
                <Link
                  to="/gloves-page"
                  className="text-gray-300 transition duration-300 hover:text-yellow-400"
                >
                  {" "}
                  Gloves Images
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="text-gray-300 transition duration-300 hover:text-yellow-400"
                >
                  How to Order Items
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-gray-300 transition duration-300 hover:text-yellow-400"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-300 transition duration-300 hover:text-yellow-400"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy"
                  className="text-gray-300 transition duration-300 hover:text-yellow-400"
                >
                  Privacy & Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="mb-4 text-xl font-semibold text-white">Follow Us</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61564456954088&mibextid=ZbWKwL"
                className="text-gray-300 transition duration-300 hover:text-blue-500"
                aria-label="Facebook"
                target="_blank"
              >
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M22.675 0H1.325C.593 0 0 .593 0 1.326v21.348C0 23.407.593 24 1.325 24h11.494v-9.294H9.689v-3.622h3.13v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.465.099 2.797.143v3.242l-1.92.001c-1.504 0-1.795.716-1.795 1.764v2.309h3.587l-.467 3.622h-3.12V24h6.115c.731 0 1.325-.593 1.325-1.326V1.326C24 .593 23.407 0 22.675 0z" />
                </svg>
              </a>
              <a
                href="https://x.com/AkilaSuppliers"
                className="text-gray-300 transition duration-300 hover:text-blue-400"
                aria-label="Twitter"
                target="_blank"
              >
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 4.557a9.848 9.848 0 0 1-2.828.775A4.932 4.932 0 0 0 23.337 3.2a9.86 9.86 0 0 1-3.127 1.195 4.924 4.924 0 0 0-8.392 4.49 13.971 13.971 0 0 1-10.15-5.145 4.924 4.924 0 0 0 1.524 6.573 4.909 4.909 0 0 1-2.23-.616v.061a4.924 4.924 0 0 0 3.946 4.827 4.922 4.922 0 0 1-2.224.085 4.927 4.927 0 0 0 4.6 3.419 9.868 9.868 0 0 1-6.102 2.104c-.395 0-.785-.023-1.17-.068a13.957 13.957 0 0 0 7.557 2.212c9.054 0 14-7.504 14-14.002 0-.213-.005-.425-.014-.637A10.026 10.026 0 0 0 24 4.557z" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="text-center text-gray-500">
          &copy; 2024 Akila Suppliers. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
