

const OurItems = [
  {
    id: 1,
    name: "Rubber Bands",
    description: "Various sizes and colors of high-quality rubber bands.",
  },
  {
    id: 2,
    name: " All Types of Gloves",
    description: "Protective gloves for various applications.",
  },
  {
    id: 3,
    name: "Aprons",
    description: "Durable aprons for kitchen and industrial use.",
  },
  {
    id: 4,
    name: "Raincort",
    description: "A range of additional safety items.",
  },
  {
    id: 5,
    name: " Surgicle Gloves",
    description: "A range of additional safety items.",
  },
  {
    id: 6,
    name: "Rubber Gloves",
    description: "A range of additional safety items.",
  },
  {
    id: 7,
    name: "Cotton Gloves",
    description: "A range of additional safety items.",
  },
  {
    id: 8,
    name: "Disposable Caps",
    description: "A range of additional safety items.",
  },
  
  {
    id: 9,
    name: "All Types of Safety Items",
    description: "A range of additional safety items.",
  },
  {
    id: 10,
    name: "Mops",
    description: "A range of additional safety items.",
  },
  {

  
    id: 11,
    name: "Goggles",
    description: "A range of additional safety items.",
  
  }
  
];

export  default OurItems;