// src/data/RubberBandData.js
import foruPoinFive from "../images/rubberbands/ruberfourPointFive.jpeg";
import oneInch from "../images/rubberbands/RuberOneInches.jpeg";
import pointFive from "../images/rubberbands/ruberPointFive.jpeg";
import threee from "../images/rubberbands/ruberThree.jpeg";
import threeColor from "../images/rubberbands/ruberThreeColors.jpeg";
import threePointFive from "../images/rubberbands/ruberThreePointFive.jpeg";
import two from "../images/rubberbands/RuberTwo.jpeg";
import twoPointFive from "../images/rubberbands//ruberTwoPointFive.jpeg";

const rubberBands = [
  {
    id: 5,
    image: foruPoinFive,
    name: "Standard Rubber Band 4.5 inches",
    description:
      "Strong and versatile, these 4.5-inch rubber bands are perfect for all your binding needs. Buy now at a price that can't be beat!",
    colors: ["Red", "Blue", "Green", "Black"],
    sizes: ["4.5 inches"],
    inStock: true,
  },
  {
    id: 6,
    image: oneInch,
    name: "Heavy-Duty Rubber Band",
    description:
      "Tackle tough tasks with these heavy-duty 1-inch rubber bands, designed for strength and durability. Now available at an amazing price!",
    colors: ["Yellow", "Orange", "Purple"],
    sizes: ["1 inch", "4 inches"],
    inStock: true,
  },
  {
    id: 7,
    image: pointFive,
    name: "Mini Rubber Band 0.5 inches",
    description:
      "Perfect for office and crafts, these 0.5-inch mini rubber bands are small but mighty. Get yours today at an unbeatable price!",
    colors: ["Pink", "White", "Gray"],
    sizes: ["0.5 inches"],
    inStock: true,
  },
  {
    id: 8,
    image: threee,
    name: "Rubber Bands 3 inches",
    description:
      "A pack of 3-inch rubber bands in assorted sizes, perfect for all your daily needs. Available now at a price you'll love!",
    colors: ["Mixed"],
    sizes: ["3 inches"],
    inStock: true,
  },
  {
    id: 9,
    image: threeColor,
    name: "3 inches Rubber Bands with Different Colors",
    description:
      "Colorful and versatile, these 3-inch rubber bands are perfect for adding a pop of color to your tasks. Buy now at an affordable price!",
    colors: ["Mixed"],
    sizes: ["3 inches"],
    inStock: true,
  },
  {
    id: 10,
    image: threePointFive,
    name: "Assorted Rubber Bands 3.5 inches",
    description:
      "Get the job done with these 3.5-inch rubber bands, available in a variety of colors. Now at a price you can't resist!",
    colors: ["Mixed"],
    sizes: ["3.5 inches"],
    inStock: true,
  },
  {
    id: 11,
    image: two,
    name: "Assorted Rubber Bands 2 inches",
    description:
      "These 2-inch rubber bands are perfect for organizing and securing small items. Available at a very affordable price!",
    colors: ["Mixed"],
    sizes: ["2 inches"],
    inStock: true,
  },
  {
    id: 12,
    image: twoPointFive,
    name: "Rubber Bands 2.5 inches",
    description:
      "Versatile 2.5-inch rubber bands for all your everyday needs. Get them now at a price that fits your budget!",
    colors: ["Mixed"],
    sizes: ["2.5 inches"],
    inStock: true,
  },

  // Add more rubber bands as needed
];

export default rubberBands;
