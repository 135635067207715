//gloves folder

import HouseholdImg from '../images/gloves/HouseHold.jpeg'
import HouseholdImg2 from '../images/gloves/HouseHoldGloves-02.jpeg'
import dotGloves from '../images/gloves/dotGloves.jpeg'
import Surgicle from '../images/gloves/glvoes.jpeg'
//ruber band image folder
import foruPoinFive from "../images/rubberbands/ruberfourPointFive.jpeg";
import oneInch from "../images/rubberbands/RuberOneInches.jpeg";
import pointFive from "../images/rubberbands/ruberPointFive.jpeg";
import threee from "../images/rubberbands/ruberThree.jpeg";
import threeColor from "../images/rubberbands/ruberThreeColors.jpeg";
import threePointFive from "../images/rubberbands/ruberThreePointFive.jpeg";
import two from "../images/rubberbands/RuberTwo.jpeg";
import twoPointFive from "../images/rubberbands//ruberTwoPointFive.jpeg";


//boots import 
import blackBoot from "../images/boots/AkilaBoot.jpeg";





// otheritem folder image
import Apron from "../images/otherItms/AkilaApron.jpeg";
import Cap from "../images/otherItms/AkilaCap.jpeg";
import KN95 from "../images/otherItms/AkilaKN95.jpeg";
import Raincort from "../images/otherItms/AkilaRaincort.jpeg";

import Serviate from "../images/otherItms/serviate.jpeg";
const items = [
  {
    id: 1,
    image: HouseholdImg,
    name: "Premium Rubber Gloves Household",
    description:
      "Experience unmatched durability and comfort with these high-quality rubber gloves, perfect for all your household tasks. Available now at an incredibly affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 2,
    image: HouseholdImg2,
    name: "Premium Rubber Gloves ",
    description:
      "Keep your hands safe and dry with these premium rubber gloves, designed for style and protection in any situation. Grab yours today at an unbeatable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 3,
    image: dotGloves,
    name: "Dot Gloves ",
    description:
      "Stay comfortable and protected during work with these dot gloves, combining grip and flexibility for all tasks. Get them now at a price you won't believe!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 4,
    image: Surgicle,
    name: "Surgical Glove",
    description:
      "Ensure your safety with these surgical gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },

  {
    id: 5,
    image: foruPoinFive,
    name: "Standard Rubber Band 4.5 inches",
    description:
      "Strong and versatile, these 4.5-inch rubber bands are perfect for all your binding needs. Buy now at a price that can't be beat!",
    colors: ["Red", "Blue", "Green", "Black"],
    sizes: ["4.5 inches"],
    inStock: true,
  },
  {
    id: 6,
    image: oneInch,
    name: "Heavy-Duty Rubber Band",
    description:
      "Tackle tough tasks with these heavy-duty 1-inch rubber bands, designed for strength and durability. Now available at an amazing price!",
    colors: ["Yellow", "Orange", "Purple"],
    sizes: ["1 inch", "4 inches"],
    inStock: true,
  },
  {
    id: 7,
    image: pointFive,
    name: "Mini Rubber Band 0.5 inches",
    description:
      "Perfect for office and crafts, these 0.5-inch mini rubber bands are small but mighty. Get yours today at an unbeatable price!",
    colors: ["Pink", "White", "Gray"],
    sizes: ["0.5 inches"],
    inStock: true,
  },
  {
    id: 8,
    image: threee,
    name: "Rubber Bands 3 inches",
    description:
      "A pack of 3-inch rubber bands in assorted sizes, perfect for all your daily needs. Available now at a price you'll love!",
    colors: ["Mixed"],
    sizes: ["3 inches"],
    inStock: true,
  },
  {
    id: 9,
    image: threeColor,
    name: "3 inches Rubber Bands with Different Colors",
    description:
      "Colorful and versatile, these 3-inch rubber bands are perfect for adding a pop of color to your tasks. Buy now at an affordable price!",
    colors: ["Mixed"],
    sizes: ["3 inches"],
    inStock: true,
  },
  {
    id: 10,
    image: threePointFive,
    name: "Assorted Rubber Bands 3.5 inches",
    description:
      "Get the job done with these 3.5-inch rubber bands, available in a variety of colors. Now at a price you can't resist!",
    colors: ["Mixed"],
    sizes: ["3.5 inches"],
    inStock: true,
  },
  {
    id: 11,
    image: two,
    name: "Assorted Rubber Bands 2 inches",
    description:
      "These 2-inch rubber bands are perfect for organizing and securing small items. Available at a very affordable price!",
    colors: ["Mixed"],
    sizes: ["2 inches"],
    inStock: true,
  },
  {
    id: 12,
    image: twoPointFive,
    name: "Rubber Bands 2.5 inches",
    description:
      "Versatile 2.5-inch rubber bands for all your everyday needs. Get them now at a price that fits your budget!",
    colors: ["Mixed"],
    sizes: ["2.5 inches"],
    inStock: true,
  },

  {
    id: 13,
    image: Apron,
    name: "Apron",
    description:
      "Protect your clothes in style with our affordable and durable aprons. Available now at a fantastic price!",
    colors: ["Mixed"],
    sizes: ["M", "L"],
    inStock: true,
  },
  {
    id: 14,
    image: Cap,
    name: "Disposable Cap",
    description:
      "Stay hygienic and comfortable with these disposable caps, available at a great price you can't miss!",
    colors: ["Mixed"],
    sizes: ["S", "M"],
    inStock: true,
  },
  {
    id: 15,
    image: KN95,
    name: "KN95 Mask",
    description:
      "Protect yourself with these high-quality KN95 masks, offered at an unbeatable price you won't find anywhere else!",
    colors: ["Mixed"],
    sizes: ["M"],
    inStock: true,
  },
  {
    id: 16,
    image: Raincort,
    name: "Raincoat",
    description:
      "Stay dry and stylish with our raincoat, complete with matching trousers. Available now at an incredibly affordable price!",
    colors: ["Mixed"],
    sizes: ["M", "L"],
    inStock: true,
  },
 
  {
    id: 17,
    image: Serviate,
    name: "Serviette",
    description:
      "Get premium-quality serviettes at a great price. Perfect for any occasion. Buy now at an affordable rate!",
    colors: ["Mixed"],
    sizes: ["S", "M"],
    inStock: true,
  },
  {
    id: 18,
    image: blackBoot,
    name: "Black ||Yellow Boot",
    description:
      "Step into durability with our black boots, designed for long-lasting wear. Available now at an unbeatable price!Yelow Color Available",
    colors: ["Mixed"],
    sizes: ["7", "8", "9"],
    inStock: true,
  },
  
];

export default items;
