import { useState, useEffect } from "react";

function ChristmasCountdown() {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const christmas = new Date(now.getFullYear(), 11, 25); // December 25
      const diff = christmas - now;
      if (diff <= 0) {
        setTimeLeft("Merry Christmas!");
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        color: "#fff",
        backgroundColor: "#333",
        padding: "10px",
      }}
    >
      <h2>🎁 Christmas Countdown 🎄</h2>
      <h1>{timeLeft}</h1>
    </div>
  );
}
export default ChristmasCountdown;