import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Ruber from "./pages/Ruber";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import ItemDetail from "./pages/ItemDetail";
import Gloves from "./pages/Gloves";
import Privacy from "./pages/Privacy";
import { Helmet } from "react-helmet";
 

import Lights from './Christmas/Lights.js'
import Snowfall from "react-snowfall";  
import ChristmasCountdown from "./Christmas/ChristmasCountdown.js";
function App() {
  return (
    <div>
      <Router>
        <Header />

        <h3 className="text-red-600 bg-green-400">
          Hurry Up! more offers(Dec) call now- 0717009059{" "}
          <b>more than five years trusted </b>
          (safety items supplier)
        </h3>
        <ChristmasCountdown />
        <Lights />
        <Snowfall />

        <title>Safety Items-Glove</title>
        <Helmet>
          <meta
            name="description"
            content="safety items gloves,rubber bands,apron,raincort,surgicle gloves,cotton gloves,boots ,serviate and all of the safety items.for best affordable prize"
          />
          <meta
            name="keywords"
            content="safety items gloves,rubber bands,apron,raincort,surgicle gloves,cotton gloves,boots ,serviate and all of the safety items.for best affordable prize"
          />

          <meta property="og:title" content="Akila Suppliers" />
          <meta
            property="og:description"
            content="Explore safety items such as gloves, masks, and aprons."
          />
          <meta
            property="og:image"
            content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
          />
          <meta property="og:url" content="https://akilasuppliers.com/" />

          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/ruber-page" element={<Ruber />} />
          <Route path="/item/:id" element={<ItemDetail />} />
          <Route path="/gloves-page" element={<Gloves />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
