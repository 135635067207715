import "./Lights.css";

function Lights() {
  return (
    <div className="lights">
      {[...Array(20)].map((_, i) => (
        <span key={i} className={`light color-${i % 5}`} />
      ))}
    </div>
  );
}

export default Lights;