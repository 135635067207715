// src/pages/ItemDetail.js
import { Helmet } from "react-helmet";
import React,{useEffect} from "react";
import { useParams } from "react-router-dom";
import gloves from "../data/glovesData";

const GlovesDetail = () => {

   useEffect(() => {

    try{
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch(e)
    {
      console.log(e)
    }
  })
  const { id } = useParams();
  const item = gloves.find((item) => item.id === parseInt(id));

  if (!item) {
    return (
      <div className="mt-10 text-center text-red-500">Item not found!</div>
    );
  }

  return (
    <div className="max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
      <Helmet>
       
        <title>Gloves Details - Akila Suppliers</title>
        <meta
          name="description"
          content="Explore Akila Suppliers' wide range of durable and versatile gloves, suitable for various needs. From cotton gloves to loova gloves, we have the right protection for you."
        />
        <meta
          name="keywords"
          content="gloves, safety gloves, cotton gloves, loova gloves, durable gloves, Akila Suppliers"
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content="Gloves Collection - Akila Suppliers"
        />
        <meta
          property="og:description"
          content="Discover the perfect gloves for your needs at Akila Suppliers. We offer a variety of high-quality gloves, ensuring safety and durability."
        />
        <meta
          property="og:image"
          content="https://example.com/gloves-og-image.jpg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/gloves" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Gloves Collection - Akila Suppliers"
        />
        <meta
          name="twitter:description"
          content="Shop for high-quality, versatile gloves at Akila Suppliers."
        />
        <meta
          name="twitter:image"
          content="https://example.com/gloves-twitter-image.jpg"
        />

        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <img
        src={item.image}
        alt={item.name}
        className="object-cover w-full h-64 rounded-t-lg"
      />
      <div className="p-4">
        <h2 className="mb-2 text-2xl font-bold">{item.name}</h2>
        <p className="mb-4 text-gray-700">{item.description}</p>
        <div className="mb-2 text-gray-600">
          <span className="font-semibold">Sizes:</span> {item.sizes.join(", ")}
        </div>
        <div className="text-gray-600">
          <span className="font-semibold">In Stock:</span>{" "}
          {item.inStock ? "Yes" : "No"}
        </div>
        <div className="mt-6">
          <a
            href="tel:+94717009059"
            className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
          >
            Call to Order
          </a>
          <a
            href="tel:+94717009059"
            className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
          >
            Inquiry
          </a>
        </div>
      </div>
    </div>
  );
};

export default GlovesDetail;
